angular.module('shared')
    .service('idp',function(BASE_PATH,$http){
        var enableMfa = function (userId) {
            return $http.post(BASE_PATH.API_URL.replace('/api', '/auth')+'v2/mfa').then(function(response){
                return response.data;
            });
        };
        var disableMfa = function (userId) {
            return $http.delete(BASE_PATH.API_URL.replace('/api', '/auth')+'v2/mfa').then(function(response){
                return response.data;
            });
        };

        return {
            enableMfa: enableMfa,
            disableMfa: disableMfa,
        };
    });
