angular.module('shared', ['ui.router', 'permission', 'permission.ui', 'ngAnimate', 'toastr', 'auth', 'maps'])
    .constant('TIME_SLOT_KEY', 'time-slot')
    .constant('PAGE_SIZE', 20)
    .filter('trustAsHtml', function ($sce) {
        return function (text) {
            return $sce.trustAsHtml(text);
        };
    })
    .constant('HOME_PAGE_OPTIONS', [
        {name: 'Lista installazioni', stateName: 'installations', needInstallationId: true},
        {name: 'Lista Gruppi', stateName: 'groupList', needInstallationId: true},
        {name: 'Lista Clienti', stateName: 'customers'},
        {name: 'Lista Utenti', stateName: 'companymanagement'},
        {name: 'Lista Installatori', stateName: 'managers'},
        {name: 'Controllo accessi', stateName: 'access-control', needInstallationId: false, isMap: false, url: 'access-control'},
        {name: 'Staff management', stateName: 'staff-gui', needInstallationId: false, isMap: false, url: 'staff-gui'},
        {name: 'Suite - Mappe', stateName: 'bms2', needInstallationId: false, isMap: false, url: 'bms2'},
        {name: 'Suite - Query', stateName: 'queries', needInstallationId: false, isMap: false, url: 'queries'},
        {name: 'Suite - Com90', stateName: 'com90', needInstallationId: false, isMap: false, url: 'com90'}
    ])
    .constant('URLS_WITHOUT_ALERT', ['/#!/videocloud/'])
    .filter('initialPageNameFromUrl', function (HOME_PAGE_OPTIONS, $state) {
        return function (input) {
            input = input || '';
            var found = {name: input};
            var optionsMapped;
            angular.forEach($state.get(), function (state) {
                // eslint-disable-next-line angular/no-private-call
                var privatePortion = state.$$state();
                if (privatePortion && privatePortion.url) {
                    var match = privatePortion.url.exec(input) || privatePortion.url.exec(input.replace('#!', ''));
                    if (match) {
                        found = state;
                    }
                }
            });
            optionsMapped = HOME_PAGE_OPTIONS.filter(function (item) {
                return item.stateName === found.name;
            })[0];
            return optionsMapped ? found.name = optionsMapped.name : found.name;

        };
    })
    .run(function (keepAlive, soundService, socketService,$timeout,toastr, $window, URLS_WITHOUT_ALERT) {});
