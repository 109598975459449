angular.module('shared').component('headerComponent', {
  templateUrl: 'app/shared/header/headerComponent.tpl.html',
  controller: function($rootScope,userInfo,$log,$uibModal,$window,UserService,BASE_PATH){
    var vm=this;
    vm.installationId=UserService.getSelectedInstallation();
    vm.eventListner=$rootScope.$on('selectedInstallationChanged', function(){
          vm.installationId=UserService.getSelectedInstallation();
      });
    vm.showUserInfo=function () {
      var modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'app/auth/userInfoDetail/userInfoPopup.html',
        controller: 'UserInfoInstanceCtrl',
        resolve: {
          user: function () {
            return vm.userInfo.userInfo;
          }
        }
      });
    };
    //FIXME
    vm.logout = function () {
      var token = localStorage.getItem('ba_t');
      if (userInfo.logoutUrl != null) {
        $window.location.href = userInfo.logoutUrl;
      } else {
        $window.location.href = BASE_PATH.BACKEND_BASE_URL + '/sso/logout?token=' + token;
      }
    };
    vm.goToChangePwd = function () {
      if (userInfo.changePwdUrl != null) {
        $window.location.href = userInfo.changePwdUrl;
      } else {
        $window.location.href = BASE_PATH.BACKEND_BASE_URL + '/bms2/#/change-password';
      }
    };
    vm.$onInit=function(){
      vm.userInfo=userInfo;
    };
    vm.isNewAuth=function(){
      return localStorage.ba_t_type === 'new';
    };
  }
});
