/* eslint-disable angular/no-services,angular/controller-as,angular/log,angular/definedundefined,angular/json-functions */

angular.module('customer')
  .controller('EditCustomerModalCtrl', function(FileUploader, $uibModal, fileReader, modalService, $http, BASE_PATH, $uibModalInstance, customerToEdit, type, UserService, $scope){
    $scope.imageBasePath = BASE_PATH.MEDIA_PATH;
    var uploader = $scope.uploader = new FileUploader({
      queueLimit: 1,
      url: BASE_PATH.API_URL + 'uploadlogo',
      headers: UserService.getHttpAuthHeader(),
    });
    // FILTERS
    uploader.filters.push({
      name: 'imageFilter',
      fn: function(item /*{File|FileLikeObject}*/, options){
        var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
        return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
      }
    });
    
    // CALLBACKS
    uploader.onWhenAddingFileFailed = function(item /*{File|FileLikeObject}*/, filter, options){
      if($scope.uploader.queue.length > 0){
        //TODO add error message
        alert('Rimuovere il file precaricato e riprovare.');
      }
      console.info('onWhenAddingFileFailed', item, filter, options);
    };
    uploader.onAfterAddingFile = function(fileItem){
      console.info('onAfterAddingFile', fileItem);
    };
    uploader.onAfterAddingAll = function(addedFileItems){
      console.info('onAfterAddingAll', addedFileItems);
    };
    uploader.onBeforeUploadItem = function(item){
      console.info('onBeforeUploadItem', item);
    };
    uploader.onProgressItem = function(fileItem, progress){
      console.info('onProgressItem', fileItem, progress);
    };
    uploader.onProgressAll = function(progress){
      console.info('onProgressAll', progress);
    };
    uploader.onSuccessItem = function(fileItem, response, status, headers){
      console.info('onSuccessItem', fileItem, response, status, headers);
    };
    uploader.onErrorItem = function(fileItem, response, status, headers){
      console.info('onErrorItem', fileItem, response, status, headers);
    };
    uploader.onCancelItem = function(fileItem, response, status, headers){
      console.info('onCancelItem', fileItem, response, status, headers);
    };
    uploader.onCompleteItem = function(fileItem, response, status, headers){
      console.info('onCompleteItem', fileItem, response, status, headers);
      if(status === 200){
        $scope.customer.customParameters.logo = response.logoId;
      }
    };
    uploader.onCompleteAll = function(){
      console.info('onCompleteAll');
    };
    $scope.type = type;
    if(customerToEdit){
      $scope.customerMaster = customerToEdit;
      $scope.customer = angular.copy($scope.customerMaster);
      try{
        if(!$scope.customer.companyType){ // cliente
          $scope.confirmDeleteMsg = 'Sicuro di volere eliminare questo cliente?';
        }else{ // installatore
          $scope.confirmDeleteMsg = 'Sicuro di volere eliminare questo installatore?';
        }
        if(!$scope.customer){
          $scope.customer = {};
        }
        if(!$scope.customer.customParameters){
          $scope.customer.customParameters = {};
        }
        $scope.imageSrc = $scope.customer.customParameters.logo;
      }catch(err){
      
      }
    }else{
      $scope.isNew = true;
      $scope.customer = {'customParameters': {'invitation': 'notSent'}};
    }
    $scope.isLoading = true;
    
    $scope.deleteCustomer = function(){
      var modalOptions = {
        closeButtonText: 'Annulla',
        actionButtonText: 'Sì',
        headerText: 'Eliminare ?',
        bodyText: 'Sicuro di volere eliminare questo cliente?'
      };
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'app/shared/popup/confirmModal/confirmModal.tpl.html',
        controller: 'ConfirmModalInstanceCtrl',
        resolve: {
          message: function(){
            return modalOptions;
          }
        }
      });
      modalInstance.result.then(function(){
        $http.delete(BASE_PATH.API_URL + 'company/' + $scope.customer.customerId).then(function(){
          $uibModalInstance.close($scope.plant);
        }, function(error){
          $scope.errorMessage = error.data.error.replace("error: ", "");
        });
      });
    };
    $scope.getFile = function(){
      $scope.progress = 0;
      fileReader.readAsDataUrl($scope.file, $scope)
        .then(function(result){
          $scope.imageSrc = result;
          if($scope.customer.customParameters == undefined){
            $scope.customer.customParameters = {};
          }
          $scope.customer.customParameters.logo = result;
        });
    };
    
    $scope.$on('fileProgress', function(e, progress){
      $scope.progress = progress.loaded / progress.total;
    });
    
    $scope.ok = function(){
      switch($scope.type){
        case 'manager':
          if($scope.isNew){
            var fd = new FormData();
            fd.append('file', $scope.imageSrc);
            fd.append('customer', JSON.stringify($scope.customer));
            $http.post(BASE_PATH.API_URL + 'manager', $scope.customer).then(function(modifiedCustomer){
              //TODO inserire loader
              $uibModalInstance.close(modifiedCustomer);
            }, function(error){
              $scope.errorMessage = {'errorObj': error.data.error, 'type': 'ServerError'};
            });
          }else{
            $http.post(BASE_PATH.API_URL + 'manager/' + $scope.customer.customerId, $scope.customer).then(function(modifiedCustomer){
              //TODO inserire loader
              $uibModalInstance.close(modifiedCustomer);
            }, function(error){
              $scope.errorMessage = {'errorObj': error.data.error, 'type': 'ServerError'};
            });
          }
          break;
        case 'customer':
          if($scope.isNew){
            $scope.customer.managerId = UserService.getUserManagerCompanyId();
            $http.post(BASE_PATH.API_URL + 'customer', $scope.customer).then(function(modifiedCustomer){
              //TODO inserire loader
              $uibModalInstance.close(modifiedCustomer);
            }, function(error){
              $scope.errorMessage = {'errorObj': error.data.error, 'type': 'ServerError'};
            });
          }else{
            $http.post(BASE_PATH.API_URL + 'customer/' + $scope.customer.customerId, $scope.customer).then(function(modifiedCustomer){
              //TODO inserire loader
              $uibModalInstance.close(modifiedCustomer);
            }, function(error){
              $scope.errorMessage = {'errorObj': error.data.error, 'type': 'ServerError'};
            });
          }
          break;
      }
    };
    $scope.cancel = function(){
      $uibModalInstance.dismiss('cancel');
    };
    $scope.inviteAdmin = function(adminEmail, isadminFlag){
      if($scope.customer.customerId){
        $http.post(BASE_PATH.API_URL + 'assignnewusertocompany', {
          userName: adminEmail,
          companyId: $scope.customer.customerId,
          role: 'Admin',
          isadmin: isadminFlag
        }).then(function(success){
          //TODO inserire loader
          $scope.InviteOk = adminEmail;
          $scope.InviteErrorMessage = false;
        }, function(error){
          $scope.InviteErrorMessage = 'Errore in invito amministratore ' + error;
        });
      }else{
      }
    };
  });
