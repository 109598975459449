/* eslint-disable */
angular.module('auth')
  .controller('UserInfoInstanceCtrl', function ($uibModalInstance, $uibModal, user, $scope, BASE_PATH, idp, blockUI) {
  $scope.user = user;
  $scope.onPremise = BASE_PATH.ON_PREMISE;
  $scope.ok = function () {
    $uibModalInstance.close();
  };
  $scope.cancel = function () {
    $uibModalInstance.dismiss();
  };
  $scope.enableMfa = function(user){
    idp.enableMfa(user.userId).then(function (result){
        var modalInstance = $uibModal.open({
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            backdrop: 'static',
            templateUrl: 'app/auth/userInfoDetail/mfa/mfa.html',
            controller: 'MfaCtrl',
            resolve: {
                user: function () {
                    return user;
                },
                mfaResult: function () {
                    return result;
                }
            }
        });
        modalInstance.result.then(function () {
            window.location.reload();
        }).catch(function (err){
            console.error(err);
        });
    });
  };
  $scope.disableMfa = function(user){
    idp.disableMfa(user.userId).then(function (){
        window.location.reload();
    });
  };
});
