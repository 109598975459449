/* eslint-disable angular/log,angular/on-watch */


angular.module('app', ['angularUtils.directives.dirPagination','ui.router', 'ngHttpStatus', 'ngConstants', 'installations', 'ui.bootstrap', 'shared', 'managers', 'users', 'plants', 'maps', 'dashboard', 'videocloud', 'ngResource', 'blockUI', 'scheduler', 'tmh.dynamicLocale']);
(function () {
  //loadingInfo
  var loadingScreen = pleaseWait({
    logo: '/images/logo-saet.png',
    backgroundColor: '#EBE5Df',
    loadingHtml: '<div class="sk-folding-cube"><div class="sk-cube1 sk-cube"></div><div class="sk-cube2 sk-cube"></div><div class="sk-cube4 sk-cube"></div><div class="sk-cube3 sk-cube"></div></div><div> Benvenuto in BMS Cloud Management </div>'
  });
  //retreave user info before bootstrap angular app
  var initInjector = angular.injector(['ng']);
  var $http = initInjector.get('$http');
  var $log = initInjector.get('$log');
  var $window = initInjector.get('$window');
  var module = angular.module('app');
  initInjector = angular.injector(['ngHttpStatus']);
  var HttpCodes = initInjector.get('HttpCodes');
  initInjector = angular.injector(['ngConstants']);
  var BASE_PATH = (initInjector.get('BASE_PATH')||{}).BACKEND_BASE_URL;

  function getParameterByName(name, url) {
    if (!url) {
      url = $window.location.href;
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  // noinspection JSCheckFunctionSignatures
  angular.element(document).ready(function () {
    fetchData().then(bootstrapApplication, function (errorData) {
      if (errorData.status === HttpCodes.FORBIDDEN) {
        $log.debug('Error in security validation for current user. message : %s - Token %s ', errorData.data.message, errorData.config.headers['x-access-token'] || errorData.config.headers['Authorization']);
        console.error(errorData.data.loginUrl + '?continue=' + encodeURIComponent($window.location.href));
        $window.location.href = errorData.data.loginUrl + '?continue=' + encodeURIComponent($window.location.href);
      } else {
        $log.debug('Error in security validation. Code: %s', errorData.status);
        //FIXME insert error message
        $window.location.href = errorData.data.loginUrl + '?continue=' + encodeURIComponent($window.location.href);
      }
    });
  });


  function fetchData() {
  	//Check querystring passed token
    var token = getParameterByName('token');
    //$log.info(' ', token);
    if (token) {
      var externalUrl = getParameterByName('externalurl');
      var idinstallation = getParameterByName('idinstallation');
      $log.info('Found token in url. Setting into ');
      localStorage.setItem('ba_t', token);

      if(!!idinstallation) {
        localStorage.setItem('ba_inst_access', idinstallation);
      }
      var redirectUrl = $window.location.href;
      if(!!externalUrl) {
        var externalUrlRegex = new RegExp('[?&]externalurl(=([^&#]*)|&|#|$)');
        var idinstallationRegex = new RegExp('[?&]idinstallation(=([^&#]*)|&|#|$)');
        redirectUrl = redirectUrl.replace(externalUrlRegex.exec($window.location.href)[0], '');
        redirectUrl = redirectUrl.replace(idinstallationRegex.exec($window.location.href)[0], '');
      }
      var tokenRegex = new RegExp('[?&]token(=([^&#]*)|&|#|$)');
      redirectUrl = redirectUrl.replace(tokenRegex.exec($window.location.href)[0], '');
      if(!!externalUrl) {
        redirectUrl = redirectUrl.replace('bms', externalUrl);
      }
      $log.info('Redirect browser to %s', redirectUrl);
      location.href = redirectUrl;
    }
    $log.debug('Make Security Call For Current User');

    var httpHeaders = {};
    if (localStorage.getItem('ba_t_type') === 'new') {
        httpHeaders = {'Authorization': 'Bearer ' + localStorage.getItem('ba_t')};
    } else {
        httpHeaders = {'x-access-token': localStorage.getItem('ba_t')};
    }
    var config = {
      headers: httpHeaders
    };

    // FIXME
    var apiPath = BASE_PATH + '/api/userinfo';
    return $http.get(apiPath, config).then(function (data) {
      $log.debug('Security Check for token OK, inserting into angular costant');
      module.constant('userInfo', data.data);
      // module.constant('LOGOUT_URL', data.logoutUrl);
      // try {
      //   $window.loading_screen.finish();
      // } catch (err) {
      //   $log.debug('window.loading_screen not available. Passing out.');
      // }
    });
  }

  /**
   * Manually Bootstrap Angular
   * @return {[type]} [description]
   */
  function bootstrapApplication() {
    // noinspection JSCheckFunctionSignatures
    angular.bootstrap(document, ['app']);
    loadingScreen.finish();
  }
}());

angular.module('app').config(function($sceDelegateProvider){
    $sceDelegateProvider.resourceUrlWhitelist([
        // Allow same origin resource loads.
        'self',
        // Allow loading from our assets domain.  Notice the difference between * and **.
        'https://s3.*.amazonaws.com/**'
    ]);
});

angular.module('app')
  .config(function ( $httpProvider, $urlRouterProvider, $resourceProvider, blockUIConfig, tmhDynamicLocaleProvider) {

    tmhDynamicLocaleProvider.localeLocationPattern('bower_components/angular-i18n/angular-locale_{{locale}}.js');
    tmhDynamicLocaleProvider.defaultLocale('it');
    // Change the default overlay message
    blockUIConfig.message = 'Attendere prego';
    blockUIConfig.autoBlock = false;
    $urlRouterProvider.otherwise('/installations');
    var initInjector = angular.injector(['ngHttpStatus']);
    var HttpCodes = initInjector.get('HttpCodes');
    $httpProvider.interceptors.push(function ($q,$window) {
      return {
        request: function (config) {
          config.headers['Accept'] = 'application/json;';
          if (localStorage.getItem('ba_t_type') === 'new') {
            config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('ba_t');
          } else {
            config.headers['x-access-token'] = localStorage.getItem('ba_t');
          }
          return config;
        },
        response: function (res) {
          if (res.status === HttpCodes.FOUND) {
            $window.location.href = rejection.data.loginUrl + '?continue=' + encodeURIComponent($window.location.href);
          }
          return res;
        },
        responseError: function (rejection) {
          if (rejection.status === HttpCodes.FORBIDDEN) {
            if (rejection.data) {
              $window.location.href = rejection.data.loginUrl + '?continue=' + encodeURIComponent($window.location.href);
            }
          } else {
            if (rejection.status === HttpCodes.FOUND) {
              $window.location.href = rejection.data.loginUrl + '?continue=' + encodeURIComponent($window.location.href);
            }
          }
          return $q.reject(rejection);
        }
      };
    });
  })
    .run(function($transitions,UserService){
        $transitions.onSuccess({},function(transition){
            UserService.setInstallation(transition.params().installationId);
        });
    });

//method includes from Polyfill
if (!String.prototype.includes) {
  String.prototype.includes = function(search, start) {
    'use strict';
    // eslint-disable-next-line angular/typecheck-number
    if (typeof start !== 'number') {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    } else {
      return this.indexOf(search, start) !== -1;
    }
  };
}


